import { Box, TabPanel, TabPanelsProps, VStack } from '@chakra-ui/react';
import { useCallback } from 'react';

import { ButtonGroup, Flex } from 'shared';
import Loader from 'shared_DEPRECATED/components/Loader';

import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import {
  PlanningBacklogHeaderMenu,
  usePlanningBacklogFilterContext,
  PlanningBacklogFilterProvider,
  SelectedGoalsProvider,
  PlanningBoardGoalsFilter as PlanningFilter,
  PlanningBoardColumnItemsCount,
  PlanningDraggableItem,
  doesDraggedChallengeExistInColumn,
} from 'features/planning';
import { PlanningBoardItems__NEW } from 'features/planning/components/Board/Items__NEW';
import { useMovePlanningChallengeMutation__NEW } from 'features/planning/hooks/mutation/useMovePlanningChallenge__NEW';
import { useMoveBacklogChallenge } from 'features/planning/hooks/useMoveBacklogChallenge';

import { Droppable } from 'shared/components/DragNDrop/Droppable';

const PlanningBacklogChallenges__NEW = () => {
  const { filteredChallenges: challenges, isLoading } =
    usePlanningBacklogFilterContext();
  const { moveItemInList } = useMoveBacklogChallenge();
  const { mutate } = useMovePlanningChallengeMutation__NEW();

  const onDrop = useCallback(
    (item: PlanningDraggableItem) => {
      if (
        doesDraggedChallengeExistInColumn({
          challenges,
          draggedItemId: item.id,
          draggedItemLocation: item.location,
          currentLocation: CHALLENGE_LOCATIONS.BACKLOG,
        })
      ) {
        return;
      }

      !item.isColumnChanged &&
        mutate({
          id: item.id,
          fromBacklog: item.fromBacklog,
          fromSprintId: item.fromSprintId,
          toBacklog: true,
          underChallengeId: item.underChallengeId,
        });
    },
    [challenges, mutate]
  );

  const onHover = useCallback(
    (
      draggedItem: PlanningDraggableItem,
      hoveredItem: PlanningDraggableItem
    ) => {
      if (
        doesDraggedChallengeExistInColumn({
          challenges,
          draggedItemId: draggedItem.id,
          draggedItemLocation: draggedItem.location,
          currentLocation: CHALLENGE_LOCATIONS.BACKLOG,
        })
      ) {
        return;
      }

      moveItemInList(draggedItem, hoveredItem);
    },
    [challenges, moveItemInList]
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Droppable
      acceptType={['challenge']}
      onDrop={({
        isColumnChanged,
        id,
        fromBacklog,
        fromSprintId,
        toSprintId,
        underChallengeId,
      }: PlanningDraggableItem) => {
        if (!isColumnChanged) {
          return;
        }

        mutate({
          id,
          fromBacklog,
          fromSprintId,
          toBacklog: true,
          toSprintId,
          underChallengeId,
        });
      }}
      onHover={challenges.length === 0 ? moveItemInList : undefined}
    >
      <Box
        style={{
          position: 'sticky',
          top: '0',
          zIndex: 111,
          backgroundColor: 'var(--bgPrimary)',
        }}
      >
        <Flex
          width="100%"
          height="2.25rem"
          alignItems="center"
          justifyContent="space-between"
          bg="var(--bgCompSecondaryBig)"
          p="8px"
        >
          <PlanningBoardColumnItemsCount
            name="challenge"
            count={challenges.length}
          />
          <ButtonGroup spacing="0.5rem" size="sm" ml="auto">
            <PlanningFilter />
            <PlanningBacklogHeaderMenu />
          </ButtonGroup>
        </Flex>
      </Box>
      <VStack align="stretch" flex="1" spacing="2px" p="8px" gap="4px">
        {/* eslint-disable-next-line */}
        <PlanningBoardItems__NEW
          challenges={challenges}
          onDrop={onDrop}
          onHover={onHover}
        />
      </VStack>
    </Droppable>
  );
};

type TPlanningBacklogChallengesTab = TabPanelsProps;
// TabPanelsProps need to be passed in order to make TabPanel component work

export const PlanningBacklogChallengesTab__NEW = (
  props: TPlanningBacklogChallengesTab
) => (
  <TabPanel
    m={0}
    p={0}
    height="100%"
    width="100%"
    overflowY="auto"
    overflowX="hidden"
    {...props}
  >
    <SelectedGoalsProvider>
      <PlanningBacklogFilterProvider>
        {/* eslint-disable-next-line */}
        <PlanningBacklogChallenges__NEW />
      </PlanningBacklogFilterProvider>
    </SelectedGoalsProvider>
  </TabPanel>
);
