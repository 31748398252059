import { useTab, UseTabProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

import { Button, Box, Flex } from 'shared';

import { Typography } from 'shared/components/Typography';

export const PlanningBacklogHeaderTab = forwardRef<
  HTMLButtonElement,
  UseTabProps
>((props, ref) => {
  const tabProps = useTab({ ...props, ref });
  const { children } = tabProps;

  const isSelected = !!tabProps['aria-selected'];

  return (
    <Flex
      flexDirection="column"
      justifyContent="stretch"
      alignItems="center"
      height="3rem"
    >
      <Button
        {...tabProps}
        h="100%"
        _focus={{ borderColor: 'transparent' }}
        variant="secondary"
        border="none"
        px={`${TAB_BUTTON_PADDING_WIDTH}px`}
      >
        <Typography
          color={isSelected ? 'black' : 'gray'}
          fontWeight="semiBold"
          type="meta"
        >
          {children}
        </Typography>
      </Button>
      <TabBottomBorder isSelected={isSelected} />
    </Flex>
  );
});

const TabBottomBorder = ({ isSelected }: { isSelected: boolean }) => (
  <Box
    marginTop="auto"
    width={`calc(100% - ${TAB_BUTTON_PADDING_WIDTH * 2}px)`}
    borderBottom={isSelected ? '2px solid var(--fgInteractive)' : 'none'}
  />
);

const TAB_BUTTON_PADDING_WIDTH = 8;
