import { IconButton } from '@chakra-ui/react';

import { Menu, MenuButton, MenuList, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  IPlanningDraftSprint,
  PlanningDraftSprintAddChallengeButton,
  PlanningDraftSprintAddLibraryChallengeButton,
} from 'features/planning';

type TPlanningDraftSprintHeaderMenu = {
  sprint: IPlanningDraftSprint;
};

export const PlanningDraftSprintHeaderMenu = ({
  sprint,
}: TPlanningDraftSprintHeaderMenu) => (
  <Menu>
    <MenuButton
      as={IconButton}
      aria-label="Options"
      icon={
        <Icon name={iconNames.plus} height="10" width="10" strokeWidth={2} />
      }
      variant={VARIANTS.TERTIARY}
    />
    <MenuList position="relative" zIndex={2}>
      <PlanningDraftSprintAddChallengeButton sprint={sprint} />
      <PlanningDraftSprintAddLibraryChallengeButton sprint={sprint} />
    </MenuList>
  </Menu>
);
