import { toastConfig } from 'lib/reactToastify/config';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { dateUtils, updateArrayById } from 'shared_DEPRECATED/utils';

import { BoardType, GoalCardType } from 'features/goal/types';
import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

export const usePrioritizeGoalOnBoardMutation = () => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();
  const { userId } = useCurrentModeUser();

  return useMutation(
    ({
      goalId,
      isPrioritized,
    }: {
      goalId: GoalCardType['goalId'];
      isPrioritized: boolean;
    }) => {
      return request({
        url: `/api/goals/${goalId}/prioritize`,
        body: {
          prioritized: !isPrioritized,
        },
      });
    },
    {
      onMutate: async ({
        goalId,
        isPrioritized,
        columnId,
      }: {
        goalId: GoalCardType['goalId'];
        isPrioritized: boolean;
        columnId: GoalCardType['columnId'];
      }) => {
        await queryClient.cancelQueries([queryKeys.goals, userId]);

        const previousValue = queryClient.getQueryData<BoardType>([
          queryKeys.goals,
          userId,
        ]);

        queryClient.setQueryData<BoardType>(
          [queryKeys.goals, userId],
          (oldVal) => {
            const selectedColumn = oldVal!.columns.find(
              (column) => column.columnId === columnId
            );

            if (!selectedColumn) {
              return oldVal!;
            }

            const updatedGoals = updateArrayById({
              array: selectedColumn.goals,
              updatedItem: {
                ...selectedColumn.goals.find((goal) => goal.goalId === goalId),
                prioritizedAt: isPrioritized
                  ? null
                  : dateUtils.utc().toISOString(),
              },
              idProperty: 'goalId',
            });

            return {
              ...oldVal!,
              columns: updateArrayById({
                array: oldVal!.columns,
                updatedItem: {
                  ...selectedColumn,
                  goals: updatedGoals,
                },
                idProperty: 'columnId',
              }),
            };
          }
        );
        return { previousValue };
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.challengeGoals, userId]);
      },
      onSuccess: (_, { isPrioritized }) => {
        toast.success(
          isPrioritized
            ? 'Unset as prioritized goal'
            : 'Set as prioritized goal',
          {
            icon: false,
            ...toastConfig,
          }
        );
      },

      onError: (err, { goalId }, context) => {
        logError(err);
        queryClient.setQueryData<BoardType | undefined>(
          [queryKeys.goals, goalId],
          context?.previousValue
        );
      },
    }
  );
};
