import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

export const useSetPrioritizedGoals = () => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();
  const { userId } = useCurrentModeUser();
  const { goalId } = useParams();

  return useMutation(
    ({ goalIds }: { goalIds: string[] }) =>
      request({
        url: `/api/goals/prioritize`,
        body: {
          goalIds,
          userId,
        },
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.goals, userId]);
        queryClient.invalidateQueries([queryKeys.challengeGoals, userId]);
        queryClient.invalidateQueries([queryKeys.goal, goalId]);
      },

      onError: (err) => {
        logError(err);
      },
    }
  );
};
