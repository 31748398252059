import { toastConfig } from 'lib/reactToastify/config';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { GoalCardType } from 'features/goal/types';
import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

import { Goal } from '../../Goal';

export const useGoalDetailsPrioritizeMutation = () => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();
  const { userId } = useCurrentModeUser();

  return useMutation(
    ({
      goalId,
      isPrioritized,
    }: {
      goalId: GoalCardType['goalId'];
      isPrioritized: boolean;
    }) => {
      return request({
        url: `/api/goals/${goalId}/prioritize`,
        body: {
          prioritized: !isPrioritized,
        },
      });
    },
    {
      onMutate: async ({ goalId, isPrioritized }) => {
        await queryClient.cancelQueries([queryKeys.goal, goalId]);

        const previousValue = queryClient.getQueryData<Goal>([
          queryKeys.goal,
          goalId,
        ]);

        queryClient.setQueryData<Goal>([queryKeys.goal, goalId], (oldGoal) => {
          return {
            ...oldGoal!,
            prioritizedAt: oldGoal!.prioritizedAt
              ? null
              : dateUtils.utc().toISOString(),
          };
        });

        return { previousValue };
      },
      onSuccess: (_, { isPrioritized }) => {
        toast.success(
          isPrioritized
            ? 'Unset as prioritized goal'
            : 'Set as prioritized goal',
          {
            icon: false,
            ...toastConfig,
          }
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.challengeGoals, userId]);
      },
      onError: (err, { goalId }, context) => {
        logError(err);
        queryClient.setQueryData<Goal | undefined>(
          [queryKeys.goal, goalId],
          context?.previousValue
        );
      },
    }
  );
};
