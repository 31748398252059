import { memo } from 'react';
import { Else, If, Then } from 'react-if';
import { For } from 'react-loops';

import { SPRINT_PLANNING_DND, useFeatureFlag } from 'featureFlags';

import { PlanningSprintModel } from 'features/planning';
import { SPRINT_STATES } from 'features/sprint/config';

import { PlanningActiveSprint } from './ActiveSprint';
import { PlanningActiveSprint__NEW } from './ActiveSprint__NEW';
import { PlanningFinishedSprint } from './FinishedSprint';
import { PlanningFinishedSprint__NEW } from './FinishedSprint__NEW';

export const PlanningActiveFinishedSprints = memo(
  ({ sprints }: { sprints: PlanningSprintModel[] }) => {
    const isSprintPlanningDndEnabled = useFeatureFlag(SPRINT_PLANNING_DND);

    return (
      <For of={sprints}>
        {(sprint: PlanningSprintModel, { isLast, index }) => {
          const reversedIndex = Math.abs(index - sprints.length + 1);

          return (
            <If condition={sprint.state === SPRINT_STATES.ACTIVE}>
              <Then>
                <If condition={isSprintPlanningDndEnabled}>
                  <Then>
                    {/*eslint-disable-next-line react/jsx-pascal-case */}
                    <PlanningActiveSprint__NEW
                      sprintId={sprint.sprintId}
                      sprintTitle={sprint.title}
                      challenges={sprint.challenges}
                      coachingTools={sprint.coachingTools}
                      sprintFormattedDate={sprint.formattedDate}
                    />
                  </Then>
                  <Else>
                    <PlanningActiveSprint
                      sprintId={sprint.sprintId}
                      sprintTitle={sprint.title}
                      challenges={sprint.challenges}
                      coachingTools={sprint.coachingTools}
                      sprintFormattedDate={sprint.formattedDate}
                    />
                  </Else>
                </If>
              </Then>
              <Else>
                <If condition={isSprintPlanningDndEnabled}>
                  <Then>
                    {/*eslint-disable-next-line react/jsx-pascal-case */}
                    <PlanningFinishedSprint__NEW
                      sprintId={sprint.sprintId}
                      sprintTitle={sprint.title}
                      challenges={sprint.challenges}
                      coachingTools={sprint.coachingTools}
                      sprintFormattedDate={sprint.formattedDate}
                      shouldScrollIntoView={isLast}
                      sprintIndex={reversedIndex}
                    />
                  </Then>
                  <Else>
                    <PlanningFinishedSprint
                      sprintId={sprint.sprintId}
                      sprintTitle={sprint.title}
                      challenges={sprint.challenges}
                      coachingTools={sprint.coachingTools}
                      sprintFormattedDate={sprint.formattedDate}
                      shouldScrollIntoView={isLast}
                    />
                  </Else>
                </If>
              </Else>
            </If>
          );
        }}
      </For>
    );
  }
);
